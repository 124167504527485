@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    }
    @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin transitions($prop, $time, $curve) {
    -webkit-transition: $prop $time $curve;
    -moz-transition: $prop $time $curve;
    transition: $prop $time $curve;
}

@mixin translate($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    -moz-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin scale($x, $y, $z) {
    -webkit-transform: scale3d($x, $y, $z);
    -moz-transform: scale3d($x, $y, $z);
    transform: scale3d($x, $y, $z);
}

@mixin positioning ($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

$smdevice-width: 767px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin smdevice {
    @media(max-width: #{$smdevice-width}) {
        @content;
    }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

%disFlex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
