app-login,
app-resetpassword,
app-forgotpassword {
    width: 75%;
    height: 90vh;
    background: #fff;
    @include positioning(fixed, $top: 50%, $left: 50%);
    @include translate(-50%, -50%, 0);
    -webkit-box-shadow: 0px 0px 31px -1px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 0px 0px 31px -1px rgba(0, 0, 0, 0.43);
    box-shadow: 0px 0px 31px -1px rgba(0, 0, 0, 0.43);
    @extend %disFlex;
    justify-content: center;
    align-items: center;

    @include smdevice {
        width: 100%;
        height: 95vh;
        box-shadow: none;
    }

    .login-holder {
        width: 400px;
        background: #1b6290;
        padding: 60px 50px;
        @extend %disFlex;
        justify-content: center;
        align-items: center;

        @include smdevice {
            width: 280px;
            padding: 20px;
        }

        @include border-radius(25px);

        &.forgot-password {
            .logo-form-holder {
                width: 100% !important;
                align-items: initial;

                img {
                    margin: auto;
                }

                p {
                    margin-left: 0;
                }

                form {
                    .mat-form-field-infix {
                        width: 100%;
                    }

                    .mat-form-field-flex {
                        width: 100% !important;
                    }
                }
            }
        }

        .logo-form-holder {
            @extend %disFlex;
            align-items: center;
            flex-direction: column;

            form {
                text-align: center;
                position: relative;

                i.login-password {
                    position: absolute;
                    right: 10px;
                    top: -30px;
                    color: #4680c1;
                    font-size: 20px;
                    cursor: pointer;
                }
            }

            img {
                width: 192px;
            }

            h2 {
                font-size: fontSize(4px);
                font-weight: fontWeight(0);
                color: #fff;
                text-align: center;
            }

            p {
                color: #fff;
                font-weight: fontWeight(0);
                font-size: fontSize(-2px);
                margin: 0 0 0 15px;
                cursor: pointer;
                position: relative;
                z-index: 10;
                text-align: left;
            }
        }
    }
}