// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

// @include mat.all-legacy-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import "assets/sass/main-import.scss";

// @import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
// @import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

:root {
  --bodyColor: #ffffff;
  --primaryColor: #1eab83;
  --headerColor: #1c6290;
  --actionCenterTextColor: #1c6290;
  --warningColor: #f44336;
  --warningDarkColor: #e01e10;
  --whiteColor: #fff;
  --attendeColor: #00c850;
  --textColor: #3a3a3a;
  --borderColor: #cecece;
  --tableRowColor: #1c629024;
  --webHeaderTextColor: #fff;
  --tableRowTextColor: #262626;
}

$ia-web-primary: mat.define-palette(mat.$indigo-palette);
$ia-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ia-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ia-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $ia-web-primary,
      accent: $ia-web-accent,
      warn: $ia-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// @include mat.all-legacy-component-themes($ia-web-theme);
@include mat.all-component-themes($ia-web-theme);

@import "ngx-toastr/toastr";
@import "../node_modules/ngx-bar-rating/themes/br-default-theme";
// @import "assets/css/DPlayer.min.css";

/* You can add global styles to this file, and also import other style files */
* {
  outline: none;
}

html,
body {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  background: var(--bodyColor);
  font-size: 1.6rem;
}

.white-bg-holder {
  border-radius: 12px;
  background: #fff;
}

.section-title {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--textColor);
  text-transform: capitalize;
}

.sub-section {
  color: var(--textColor);
  letter-spacing: 0.32px;
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: capitalize;
  margin: 0;
  &.with-border {
    border-bottom: 1px solid var(--primaryColor);
    padding-bottom: 10px;
  }
  &.sm {
    font-size: 1.3rem;
  }
}

.ion-anchor {
  font-weight: 700;
  color: var(--primaryColor);
  cursor: pointer;
}

.cp {
  cursor: pointer;
}

.mat-checkbox-label {
  color: var(--textColor);
  font-size: 1.4rem;
  font-weight: 400;
}


.data-section {
  color: var(--textColor);
  letter-spacing: 0.12px;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 20px;
  label {
    margin: 0;
  }
  p {
    margin: 0;
    font-weight: 700;
    word-break: break-word;
  }
}


.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background, .mdc-checkbox__ripple {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
}
.mdc-checkbox{
  margin-right: -8px !important;
}



.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primaryColor) !important;
  --mdc-switch-selected-handle-color: var(--primaryColor) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primaryColor) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primaryColor) !important;
  --mdc-switch-selected-focus-handle-color: var(--primaryColor) !important;
  --mdc-switch-selected-hover-handle-color: var(--primaryColor) !important;
  --mdc-switch-selected-pressed-handle-color: var(--primaryColor) !important;
  --mdc-switch-selected-focus-track-color: var(--primaryColor) !important;
  --mdc-switch-selected-hover-track-color: var(--primaryColor) !important;
  --mdc-switch-selected-pressed-track-color: var(--primaryColor) !important;
  --mdc-switch-selected-track-color: var(--primaryColor) !important;
}



.system-usage {
  font-weight: 700;
  color: #3e72b4;
  font-size: 1.4rem;
}
