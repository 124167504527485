@import "../base/colors";
@import "../helpers/mixins";

.ion-table {
  border-collapse: separate;
  border-spacing: 0;
  thead {
    tr {
      background: #ebebeb;
      th {
        padding: 12px 20px;
        background: #ebebeb;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.12px;
        color: var(--textColor);
        text-transform: uppercase;
        border: 0;
        border-top: 2px solid var(--primaryColor);
        &:first-child {
          border-left: 1px solid var(--primaryColor);
        }
        &:last-child {
          border-right: 1px solid var(--primaryColor);
        }
      }
    }
  }
  tr {
    background: var(--whiteColor);
    &:nth-child(odd) {
      background: var(--tableRowColor);
    }
    &:last-child {
      td {
        border-bottom: 1px solid var(--primaryColor);
        &:first-child {
          border-left: 1px solid var(--primaryColor);
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-right: 1px solid var(--primaryColor);
          border-bottom-right-radius: 5px;
        }
      }
    }
    td {
      padding: 12px 20px;
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 0.12px;
      color: var(--textColor);
      border: 0;
      .data-section {
        font-size: 1.2rem;
        margin: 0 0 10px 0;
        &:last-child {
          margin: 0;
        }
        label {
          font-weight: 900;
          color: var(--tableRowTextColor);
          font-size: 1rem;
          text-transform: uppercase;
        }
        p {
          margin: 0;
          font-size: 1.2rem;
          font-weight: 600;
        }
        .comma-separated-list {
          p {
            &:last-child {
              span {
                display: none;
              }
            }
          }
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: inline-flex;
        flex-wrap: wrap;
        line-height: 28px;
        &.list-data-set {
          li {
            position: relative;
            margin-right: 5px;
            &.merge-state {
              display: flex;
              align-items: flex-start;
              img {
                width: 40px;
              }
            }
            &:after {
              content: ",";
              @include positioning(absolute, $right: 0, $bottom: 0);
            }
            &:last-child {
              margin-right: 0px;
              span {
                padding: 0;
              }
              &:after {
                display: none;
              }
            }
            span {
              padding-right: 5px;
            }
          }
        }
      }
      &:first-child {
        border-left: 1px solid var(--primaryColor);
      }
      &:last-child {
        border-right: 1px solid var(--primaryColor);
      }
      .grouped-data {
        span {
          &:first-child {
            font-weight: 700;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
