@import "../helpers/mixins";

.ion-dialog-wrapper {
    mat-dialog-container {
        border: 1px solid var(--primaryColor);
        box-shadow: 0px 3px 6px #00000029;
        h2 {
            &.section-title {
                background: none;
                padding: 0;
                margin: 0 0 20px 0;
                color: var(--textColor);
                border-bottom: 1px solid var(--primaryColor);
                padding-bottom: 15px;
            }
            i {
                font-size: 2.6rem;
            }
        }
        .scroll-holder {
            max-height: 85vh;
            overflow-y: auto;
        }
    }
    .mat-mdc-dialog-surface{
        padding: 30px;
    }
}
