@import "../base/colors";
@import "../helpers/mixins";

input::placeholder {
  font-size: 1.3rem;
}

input:-ms-input-placeholder {
  font-size: 1.3rem;
}

input::-ms-input-placeholder {
  font-size: 1.3rem;
}

hr {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 20px;
  opacity: 1;
}

.ion-form-group {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  .label-text {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--textColor);
    text-transform: capitalize;
    display: block;
    width: 100%;
    &.inline {
      font-weight: 600;
    }
  }
  textarea {
    width: 100%;
    height: 120px;
    background: #fff;
    border: 1px solid var(--borderColor);
    @include border-radius(5px);
    font-size: 1.2rem;
    @include transitions(all, 0.3s, ease-in);
    color: var(--textColor);
    padding: 10px;
    &.ng-invalid.ng-touched {
      border: 1px solid var(--warningColor);
      @include transitions(all, 0.3s, ease-in);
    }
    &:disabled {
      background: #eee;
    }
  }
  input,
  select {
    width: 100%;
    height: 34px;
    background: #fff;
    border: 1px solid var(--borderColor);
    @include border-radius(5px);
    font-size: 1.2rem;
    @include transitions(all, 0.3s, ease-in);
    color: var(--textColor);
    padding: 0 10px;
    &[type="file"] {
      border: 0;
      padding: 0;
      background: none;
    }
    &.xs {
      width: 20%;
    }

    &.ng-invalid.ng-touched {
      border: 1px solid var(--warningColor);
      @include transitions(all, 0.3s, ease-in);
    }
    &:disabled {
      background: #eee;
    }
  }
  .dropdown-holder {
    width: 100%;
    height: 28px;
    position: relative;
    border: 1px solid #cecece;
    @include border-radius(4px);
    display: flex;
    align-items: center;
    background: var(--whiteColor);
    .current-value {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 15px;
      cursor: pointer;
      p {
        width: calc(100% - 40px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        font-size: 1.2rem;
        font-weight: 600;
        color: #424242;
      }
      .arrow {
        width: 30px;
        height: 100%;
        border-left: 1px solid #cecece;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    ul.dropdown-values {
      width: 100%;
      max-height: 200px;
      display: flex;
      flex-direction: column;
      background: #eee;
      overflow-y: auto;
      flex-wrap: nowrap;
      @include positioning(absolute, $top: 24px);
      transform-origin: top;
      transform: scale3d(1, 0, 1);
      @include transitions(all, 0.3s, ease-in);
      z-index: 9;
      &.open {
        transform-origin: top;
        transform: scale3d(1, 1, 1);
        @include transitions(all, 0.3s, ease-in);
      }
      li {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #cecece;
        padding: 0 15px;
        cursor: pointer;
        &:last-child {
          border: 0;
          padding-bottom: 0;
          margin: 0;
        }
      }
    }
  }
}
